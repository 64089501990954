<template>
  <ThankYouPage />
</template>

<script>
import AbstractPage from 'chimera/all/components/page/AbstractPage'
import ThankYouPage from 'chimera/all/themes/blueflow/page/ThankYou/ThankYouPage'

export default {
  components: {
    ThankYouPage,
  },

  extends: AbstractPage,

  /**
   * @returns {object}
   */
  data() {
    return {
      headTitle: 'Muchas gracias por su solicitud!',
      headDescription:
        'Compara empresas de Comparabombasdecalor.es. Cotizaciones rápidas, confiables y únicas de su región. Ahorre hasta un 40%.',
      path: '/solicitudes-de-cotizacion/solicitud-completada',
    }
  },
}
</script>
